/* You can add global styles to this file, and also import other style files */
@import '../node_modules/angular-notifier/styles.scss';

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.btn-loading {
  display: block;
  text-indent: -9999em;

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: rgb(196, 196, 196);
    animation: spinner .8s linear infinite;
  }
}

$spinner-width: 50px;

.spinner {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  width: $spinner-width;
  height: $spinner-width;
  margin-top: -$spinner-width/2;
  margin-left: -$spinner-width/2;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: rgb(196, 196, 196);
  animation: spinner .8s linear infinite;
}

// When a content is deleted we make it transparent
.deleted {
  opacity: 0.2;
}
